export enum API {
  /* Debug */
  // BASE = 'http://localhost:52498',
  
  /* Internal */
//  BASE = 'http://192.168.0.50/CoscollaApi',

  /* Produccion */
  // BASE = 'https://api.dione.ar',

  /*Preprod*/
  // BASE = 'https://testapi.coscolla.com.ar',

  /*Prod*/
  BASE = 'https://api.coscolla.com.ar',

  
  /* External Demo FRONTAPI */
  FRONTAPI_BASE = 'http://www.google.com.ar',


  HOME_HEADER = '/api/Landing/BannerList',
  
  PROMOCION = '/api/Landing/PromotionsList',

  HOME_BRANDS = '/api/Landing/BrandsList',
  HOME_PRODUCTS = './assets/data/home/products.json',
  // HOME_SOLUTIONS = './assets/data/home/solutions.json',
  HOME_SOLUTIONS = '/api/Landing/SolucionList',

  HOME_LINKS = './assets/data/home/links.json',

  ACCOUNT_FILEDOWNLOAD = '/api/AccountApi/FileDownload',
  LOGIN = '/api/AccountApi/Login',
  REGISTER = '/api/AccountApi/Register',
  REGISTER_WITHOUTLOGIN = '/api/AccountApi/RegistrarUsuarioPedidoSinLogin',
  ACTIVATE = '/api/AccountApi/Activate',
  VALIDATEKEY = '/api/AccountApi/ValidateKey',
  REGISTER_CONFIRM = '',
  PASSWORD_CHANGE = '/api/AccountApi/ChangePassword',
  PASSWORD_RESET = '/api/AccountApi/ResetPassword',
  PROFILE_VIEW = '',
  PROFILE_EDIT = '/api/AccountApi/Edit',
  GET_USER_DATA = '',
  PROVINCE_LISTA = '/api/AccountApi/Provincias',
  LOGIN_STATUS = '/api/AccountApi/Status',

  FILE_DOWNLOAD = '/api/Products/PricesList',

  PRODUCT_LIST = '/api/Products/List',
  PRODUCT_VIEW = '/api/Products/View',
  PRODUCT_CATEGORIES = '/api/Products/Categories',
  PRODUCT_BRANDS = '/api/Products/BrandsAndTags',
  PRODUCT_TAGS = '/api/Products/BrandsAndTags',
  PRODUCT_TOPSALES = '/api/Products/TopSales',
  PRODUCT_MARCAS = '/api/Products/Marcas',
  PRODUCT_FILTROS_NEUMATICOS = '/api/Products/CombosNeumaticos',

  
  CART_LIST = '/api/Cart/List',
  CART_PENDING_MP = '/api/Cart/GetPendingMP',
  CART_CONFIRM = '/api/Cart/Confirm',
  CART_UPDATE = '/api/Cart/Update',
  CART_COMBOS = '/api/Cart/GetCombos',
  CART_STATUS = '/api/Cart/Status',
  CART_GETPAYMENT = '/api/Cart/GetPayment',
  CART_PAYMENTCALLBACK = '/api/Cart/PaymentCallback',
  CART_CHECKPAYMENT = '/api/Cart/CheckPayment',
  CART_TAXES = '/api/Cart/GetTaxes',
  CART_INVOICE = '/api/Cart/SaveInvoice',
  CART_PEDIDO = '/api/Cart/SavePedido',

  FAVORITE_LIST = '/api/Favorites/List',
  FAVORITE_UPDATE = '/api/Favorites/Update',

  ORDERS_LIST = '',
  CATALOGS_LIST = '/api/landing/CatalogoList',
  INSTRUCTIONS_LIST = '/api/instructivos/InstructivoList',
  //INSTRUCTIONS_LIST = '/api/landing/InstructivoList',
  WEBINAR_LIST = '/api/landing/WebinarList',
  BRANCHES_LIST = '/api/landing/SucursalList',
  STORIES_LIST = '/api/landing/CasosDeExitosList',
  ABOUTUS_LIST = '/api/Landing/ManualDeMarcaList',
  VENDORS_LIST = '/api/Landing/ListVendedoresXSucursal',
  DOLAR_QUOTATION = '/api/Landing/Cotizacion',
  CONTACT = '/api/Landing/Contact',
  SUBSCRIBE_MAILLIST = '/api/Landing/SubscribeMailList',
  SAVE_PROJECT = '/api/Projects/SaveProject',

  TRANS_LIST = '/api/Landing/TransportsList',
  ADDRESSES_LIST = '/api/Landing/AddressesList',

  CONTRANS_LIST = '/api/Landing/ConTrasportesList',

  ART_BUSCAR = '/api/Articulos/Buscar',

  HISTORY_LIST = '/api/AccountApi/GetHistorial',

  OPCIONESPAGO_LIST = '/api/Landing/OpcionesPagoList',

  HISTORY_DOWNLOAD = '/api/AccountApi/HistorialFileDownload',

  CART_VALIDATECOUPON = '/api/Cart/ValidateCoupon',
  CART_VALIDATECALLBACK = '/api/Cart/ValidateCallbackParams',

  CONDFISCALVTAS_LIST = '/api/AccountApi/CondicionesFiscVtas',

  PAISES_LIST = '/api/AccountApi/Paises',

  LOCALIDADES_LIST = '/api/AccountApi/Localidades',

  HISTORY_COPY = '/api/AccountApi/HistorialCopy',
  ACCOUNT_CONDFISVALUE = '/api/AccountApi/GetCondFiscalVtasValues',
  PRODUCT_IDENVASE = '/api/Products/GetIdEnvase',

  ACCOUNT_CONDFISVALUEIVA = '/api/AccountApi/GetCondFiscalVtasDisclaimer',
  LANDING_SENDINBLUE = '/api/Landing/SubscribeSendinblue',
  SET_EMAIL = '/api/AccountApi/SetEMail',
  EXIST_USER = '/api/AccountApi/ExistUser',
  DATOS_USER = '/api/AccountApi/datosUser',
  PRODUCT_DISCOUNTS = '/api/Products/GetDiscounts',
  PRODUCT_REFERENCEGROUP = '/api/Products/GetReferenceGroups',
  TIPODOC_LIST = '/api/AccountApi/GetTipoDocValues',
  WEBCONDFISCALTIPODOC_LIST = '/api/AccountApi/GetWebCondFiscalTipoDocValues',
  CODCLIGENERIC_GET = '/api/AccountApi/CodCliGenericGet',
}
