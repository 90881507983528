import { Injectable } from '@angular/core';
import { Product } from './product.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ICategory, ICategoryResponse } from 'src/app/interfaces/category.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProductsResponse, IProductRequest, IProduct, IFileDownload, IGetDiscountRequest, IGetDiscountResponse } from 'src/app/interfaces/product.interface';
import { ITag, ITagResponse } from 'src/app/interfaces/tag.interface';
import { IBrand, IBrandResponse } from 'src/app/interfaces/brand.interface';
import { API } from '../api.enum';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public viewType: string = Product.CARD;
  public products: IProduct[] = [];
  public categories: ICategory[] = [];
  public tags: ITag[] = [];
  public brands: IBrand[] = [];
  public request: IProductRequest;
  public page = 0;
  public finishPage = 15;
  public favorites: IProduct[] = [];

  public idEnvase: number = null;
  public priceEnvase: number = null;
  public selectedCategory: number = null;

  public cat = new BehaviorSubject<number>(0);
  public prod: any;

  public description : string = '';

  constructor(
    private http: HttpClient
  ) {
    this.resetRequest();
  }

  public resetRequest(): void {
    this.request = {
      Description: '',
      Category: '',
      Brand: 0,
      Tag: 0,
      PriceMin: 0,
      PriceMax: 0,
      Order: 0,
      Page: 0,
      PageSize: Product.PAGE_SIZE as number,
      Ancho: '',
      RelacionDeAspecto: '',
      Diametro: '',
      RunOnFlat : '',
      Temporada :'',
      Marcas : '',
      FichaTecnica: '',
      EsDireccional: '',
      Antirrobo: '',
      Llanta: '',
      Marca: '',
      Modelo: ''
    }
  }

  public getProducts(): Observable<IProductsResponse> {
    return this.http.post<IProductsResponse>(API.BASE + API.PRODUCT_LIST, this.request);
  }


  public getProductsTest(): Observable<IProductsResponse> {
    this.prod = this.http.post<IProductsResponse>(API.BASE + API.PRODUCT_LIST, this.request);
    return this.prod
  }

  public getMarcas(): Observable<any> {

    return this.http.post<any>(API.BASE + API.PRODUCT_MARCAS, this.request);
    // return new Observable((observer) => {
    //   this.http.post<any>(API.BASE + API.PRODUCT_MARCAS, {}).subscribe((asyncData: any) => {
    //     observer.next(asyncData.Marcas);
    //     observer.complete();
    //   })
    // });
  }
  public getFiltrosNeumaticos(): Observable<any> {

    return this.http.post<any>(API.BASE + API.PRODUCT_FILTROS_NEUMATICOS, this.request);
    // return new Observable((observer) => {
    //   this.http.post<any>(API.BASE + API.PRODUCT_MARCAS, {}).subscribe((asyncData: any) => {
    //     observer.next(asyncData.Marcas);
    //     observer.complete();
    //   })
    // });
  }

  public changeCategoriaId(id:number){
        this.cat.next(id);
  }

  // public getEnvase(): any {
  //   this.http.post<any>(API.BASE + API.PRODUCT_IDENVASE, {}).subscribe((value: any) => {
  //     this.idEnvase = value.Id;

  //     this.view(value.Id).subscribe((data: IProduct) => {
  //       console.log("Seteo el valor de priceEnvase");
  //       this.priceEnvase = data.Price;
  //     });
  //   });
  // }

  public getEnvase(): Observable<any> {
    return this.http.post<any>(API.BASE + API.PRODUCT_IDENVASE, {});
    // .subscribe((value: any) => {
    //   this.idEnvase = value.Id;

    //   this.view(value.Id).subscribe((data: IProduct) => {
    //     console.log("Seteo el valor de priceEnvase");
    //     this.priceEnvase = data.Price;
    //   });
    // });
  }

  public getEnvaseIdValue(): number {
    return this.idEnvase != null ? this.idEnvase : null;
  }

  public getEnvasePrice(): number {
    return this.priceEnvase != null ? this.priceEnvase : null;
  }

  public getCategories(): Observable<ICategory[]> {
    return new Observable((observer) => {
      this.http.post<ICategoryResponse>(API.BASE + API.PRODUCT_CATEGORIES, {}).subscribe((asyncData: ICategoryResponse) => {
        observer.next(asyncData.Categories);
        observer.complete();
      });
    });
  }

  public getTags(Category: string): Observable<ITag[]> {
    return new Observable((observer) => {
      return this.http.post<ITagResponse>(API.BASE + API.PRODUCT_TAGS, { Category }).subscribe((asyncData: ITagResponse) => {
        observer.next(asyncData.Tags);
        observer.complete();
      });
    });
  }

  public getBrands(Category: string): Observable<IBrand[]> {
    return new Observable((observer) => {
      return this.http.post<IBrandResponse>(API.BASE + API.PRODUCT_BRANDS, { Category }).subscribe((asyncData: IBrandResponse) => {
        observer.next(asyncData.Brands);
        observer.complete();
      });
    });
  }

  public list(descp: string): Observable<IProductsResponse> {
    return this.http.post<IProductsResponse>(API.BASE + API.PRODUCT_LIST, {
      Description: descp == null ? '' : descp,
      Page: '0'
    });
  }

  public view(id: number): Observable<IProduct> {
    return this.http.post<IProduct>(API.BASE + API.PRODUCT_VIEW, {
      ProductId: id
    });
  }

  public downloadFile(): Observable<IFileDownload> {
    return this.http.post<IFileDownload>(
      API.BASE + API.ACCOUNT_FILEDOWNLOAD, {

    }
    );
  }

  public getFavorites(): Observable<IProductsResponse> {
    return this.http.post<IProductsResponse>(API.BASE + API.FAVORITE_UPDATE, {});
  }

  //   public searchProducts(): Observable<any> {
  //     console.log("searchProducts");

  // //     request.AddHeader("content-type", "application/x-www-form-urlencoded");
  // // request.AddParameter("application/x-www-form-urlencoded", "grant_type=client_credentials&client_id=%24%7Baccount.clientId%7D&client_secret=YOUR_CLIENT_SECRET&audience=YOUR_API_IDENTIFIER", ParameterType.RequestBody);
  // // IRestResponse response = client.Execute(request);

  //     let body = JSON.stringify("grant_type=password&username=eantunez&password=123");
  //     // const params = new HttpParams()
  //     // .set('application/x-www-form-urlencoded', 'grant_type=password&username=eantunez&password=123');

  //     const hdr = {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }

  //     this.http.post<any>(API.APP_API + 'token', body, { 'headers': hdr }).subscribe((asyncData: any) => {
  //       console.log(asyncData);


  //     });

  //     const headers = {
  //       'Authorization': 'bearer ',
  //       'Content-type': 'application/json'
  //     }

  //     return this.http.post<any>(API.APP_API + API.ART_BUSCAR, {}, { 'headers': headers });

  //   }

}
